import { Component } from '@angular/core';
import jsonPreprocessingTutorial from '../../../../locale/data/preprocessing-tutorial.json';

@Component({
  selector: 'app-preprocessing-group-project',
  templateUrl: './preprocessing-group-project.component.html',
  styleUrls: ['./preprocessing-group-project.component.sass']
})
export class PreprocessingGroupProjectComponent {
  rows: any = jsonPreprocessingTutorial;

}
