import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-tutorial-nav',
  templateUrl: './tutorial-nav.component.html',
  styleUrls: ['./tutorial-nav.component.sass']
})
export class TutorialNavComponent {
  @Input() node : any;
  @Input() level : any = 0;
  @Input() nodeIndex : any = 0;
  @Input() parentIndexLabel : any = '';
  
  ngAfterViewInit() {
    $('a').on("click",(e)=>{
      var hash = (<any>e.currentTarget).hash;
      if (hash) {
        e.preventDefault();
        window.location.hash = hash;
        document.querySelector(hash).scrollIntoView({ behavior: "smooth" });
      }
    });
  }

  getIndexLabel(nodeIndex, parentIndexLabel) {
    return (parentIndexLabel ? parentIndexLabel : '') + (nodeIndex+1) + '.';
  }

  getTitleHtml(titleHtml, level, nodeIndex, parentIndexLabel) {
    let ident = '';
    return ident + this.getIndexLabel(nodeIndex, parentIndexLabel) + '&nbsp;' + titleHtml;
  }

  getChildIndicator(children) {
    return children && children.length > 0 ? ' <i class="fa-solid fa-chevron-right"></i>' : '';
  }

  getChapterLink(nodeId) {
    return window.location.pathname + '#' + nodeId;
  }
}
