<div class="container-fluid bg-dark">
    <div class="container-fluid bg-dark text-light">
        <div class="row">
            <div class="col-lg-4 col-xl-3 d-none d-lg-block">
                <nav id="navbar-tutorial" class="navbar navbar-dark bg-dark text-light flex-column align-items-stretch p-3 position-sticky top-0 translate-in-from-bottom">
                    <nav class="nav nav-pills flex-column">
                        <app-tutorial-nav *ngFor="let node of rows; let i = index;" [node]="node" [nodeIndex]="i" classX="nav"></app-tutorial-nav>
                    </nav>
                </nav>
            </div>
            <div class="col-lg-8 col-xl-9">
                <div data-bs-smooth-scroll="true" tabindex="0" class="h-100 pe-4 position-relative overflow-hidden">
                    <app-tutorial-content *ngFor="let node of rows; let i=index" [id]="node.id" [node]="node" [nodeIndex]="i"></app-tutorial-content>
                </div>
            </div>
        </div>
        <div class="toast-container position-fixed top-0 start-50 translate-middle-x p-3 text-dark">
            <div id="liveToast" class="toast" role="alert" aria-live="assertive" aria-atomic="true">
                <div class="toast-header">
                    <strong class="me-auto">Copied to clipboard!</strong>
                    <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                </div>
                <div class="toast-body"></div>
            </div>
        </div>
    </div>
</div>