import { Component, OnInit, Inject } from '@angular/core';
import jsonProjectTutorial from '../../../../locale/data/project-tutorial.json';

@Component({
  selector: 'app-running-group-project',
  templateUrl: './running-group-project.component.html',
  styleUrls: ['./running-group-project.component.sass']
})
export class RunningGroupProjectComponent {
  rows: any = jsonProjectTutorial;

}
