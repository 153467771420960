<ng-container *ngTemplateOutlet="tree;context:{node:node, level:level, nodeIndex:nodeIndex, parentIndexLabel:parentIndexLabel}">
</ng-container>

<ng-template #tree let-node="node", let-level="level", let-nodeIndex="nodeIndex", let-parentIndexLabel="parentIndexLabel">
    <a [class]="'nav-link ms-0 my-0' + (level > 0 ? ' collapse' : '')" [href]="getChapterLink(node.id)" [innerHTML]="getTitleHtml(node.titleHtml, level, nodeIndex, parentIndexLabel) + getChildIndicator(node.children)"></a>
    <nav *ngIf="node.children && node.children.length > 0;" class="nav nav-pills flex-column ms-4 collapsible-scrollspy">
        <ng-container *ngFor="let child of node.children; let i = index;">
            <ng-container *ngTemplateOutlet="tree;context:{node:child, level:(level+1), nodeIndex:i, parentIndexLabel:getIndexLabel(nodeIndex, parentIndexLabel)}">
            </ng-container>
        </ng-container>
    </nav>
</ng-template>