import { Component } from '@angular/core';
import jsonProcessingTutorial from '../../../../locale/data/processing-tutorial.json';

@Component({
  selector: 'app-processing-group-project',
  templateUrl: './processing-group-project.component.html',
  styleUrls: ['./processing-group-project.component.sass']
})
export class ProcessingGroupProjectComponent {
  rows: any = jsonProcessingTutorial;

}
