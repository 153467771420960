<ng-container *ngTemplateOutlet="listTable;context:{list:list}">
</ng-container>

<ng-template #listTable let-list="list">
    <div style="overflow-x: auto;">
    <table *ngIf="list != undefined && list.length > 0" class="card card-body text-dark table table-responsive table-secondary table-bordered border-dark">
        <tbody class="d-table">
            <tr *ngFor="let item of list">
                <td *ngIf="item.separator" colspan="2" class="bg-white border-white">&nbsp;</td>
                <td *ngIf="item.arrow" colspan="2" class="bg-white border-white text-center text-secondary"><i class="fa-solid fa-down-long fs-1"></i></td>
                <td *ngIf="item.titleHtml != undefined" [innerHTML]="item.titleHtml" class="table-active font-monospace fw-semibold text-center position-relative" style="z-index: 1;" colspan="2"></td>
                <td *ngIf="item.headerHtml != undefined" [innerHTML]="item.headerHtml" [class]="'table-active font-monospace fw-semibold position-relative' + (isSmallDevice() ? '': ' text-nowrap')" style="z-index: 1;"></td>
                <td *ngIf="item.url == undefined && item.textHtml != undefined" [attr.colspan]="item.headerHtml != undefined ? 1 : 2" [class]="'w-100 position-relative' + (item.mono ? ' font-monospace' : '')" style="z-index: 1;">
                    <div class="row position-relative" style="z-index: 1;">
                        <span class="col-auto" [innerHTML]="item.textHtml"></span>
                        <a *ngIf="item.copy" role="button" class="col-auto btn btn-sm btn-outline-primary col-auto d-none d-md-block align-self-center" (click)="copyMessage(item.textHtml)">
                            <i class="fa fa-fw fa-copy"></i>Copy
                        </a>
                    </div>
                </td>
                <td *ngIf="item.url != undefined" [attr.colspan]="item.headerHtml != undefined ? 1 : 2" class="text-center w-100">
                    <div class="row position-relative" style="z-index: 1;">
                        <a *ngIf="item.textHtml != undefined" [href]="item.url" class="col-auto" target="_blank" [innerHTML]="item.textHtml"></a>
                        <a *ngIf="item.textHtml == undefined" [href]="item.url" class="col-auto" target="_blank" [innerHTML]="item.url"></a>
                        <a *ngIf="item.copy" role="button" class="col-auto btn btn-sm btn-outline-primary d-none d-md-block align-self-center" (click)="copyMessage(item.url)">
                            <i class="fa fa-fw fa-copy"></i>Copy
                        </a>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    </div>
</ng-template>