import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-tutorial-numbered-list',
  templateUrl: './tutorial-numbered-list.component.html',
  styleUrls: ['./tutorial-numbered-list.component.sass']
})
export class TutorialNumberedListComponent {
  @Input() numberedList : any;
  @Input() rootElement : boolean = true;

}
