<ng-container *ngTemplateOutlet="tree;context:{node:node,level:level,nodeIndex:nodeIndex,parentNodeIndex:parentNodeIndex,parentIndexLabel:parentIndexLabel}">
</ng-container>

<ng-template #tree let-node="node" let-level="level" let-nodeIndex="nodeIndex" let-parentNodeIndex="parentNodeIndex" let-parentIndexLabel="parentIndexLabel">
    <section [id]="node.id">
        <div class="pb-3">
            <div [class]="'mb-3 bg-dark border-secondary translate-in-from-' + (nodeIndex % 2 == 0 ? 'left' : 'right') + '-delayed-' + (nodeIndex + parentNodeIndex)"
                style="opacity: 0;">
                <div class="row no-gutters justify-content-center">
                    <div [class]="node.image != undefined || (node.images != undefined && node.images.length > 0) ? 'col-md-9' : 'col-12'" style="position: relative;">
                        <div class="card-body">
                            <h2 *ngIf="level == 0" [class]="'card-title row justify-content-between text-' + (node.image != undefined || (node.images != undefined && node.images.length > 0) ? (nodeIndex % 2 == 0 ? 'start' : 'start') : 'start')">
                                <span [innerHTML]="getTitleHtml(node.titleHtml, level, nodeIndex, parentIndexLabel)"></span>
                            </h2>
                            <h3 *ngIf="level != 0" [class]="'card-title row justify-content-between text-' + (node.image != undefined || (node.images != undefined && node.images.length > 0) ? (nodeIndex % 2 == 0 ? 'start' : 'start') : 'start')">
                                <span [innerHTML]="getTitleHtml(node.titleHtml, level, nodeIndex, parentIndexLabel)"></span>
                            </h3>
                            <hr class="featurette-divider">
                            <h5 *ngIf="node.textHtml != undefined" [class]="'card-text position-relative text-' + (node.image != undefined || (node.images != undefined && node.images.length > 0) ? 'start' : 'start')" [innerHTML]="node.textHtml" style="z-index: 3;"></h5>
                            <app-tutorial-numbered-list *ngIf="node.numberedList != undefined && node.numberedList.length > 0" [numberedList]="node.numberedList"></app-tutorial-numbered-list>
                            <app-tutorial-list *ngIf="node.list != undefined && node.list.length > 0" [list]="node.list"></app-tutorial-list>
                            <div *ngIf="node.badges && node.badges.length > 0" class="d-inline-flex flex-wrap gap-2 py-5">
                                <div *ngFor="let badge of node.badges">
                                    <div [class]="'text-start p-2 px-3 fs-6 fw-normal lh-base text-wrap badge ' + (badge.type == 'info' ? 'text-bg-primary' : (badge.type == 'warning' ? 'text-bg-warning' : (badge.type == 'error' ? 'text-bg-danger' : 'text-bg-secondary')))">
                                        <i [class]="'fa fa-fw ' + (badge.type == 'info' ? 'fa-info-circle' : (badge.type == 'warning' ? 'fa-exclamation-triangle text-danger' : (badge.type == 'error' ? 'fa-ban' : 'fa-info-circle')))" style="height: 20px;"></i>
                                        <span [innerHTML]="'&nbsp;' + badge.textHtml" class="position-relative" style="z-index: 1;"></span>
                                    </div>
                                </div>
                            </div>
                            <div [class]="'row text-center card-img-overlay align-items-end p-4 d-none ' + (node.textHtml != undefined ? 'd-xxl-flex' : '')">
                                <hr *ngIf="node.buttons !=undefined && node.buttons.length > 0" class="featurette-divider visible-xs pb-2">
                            </div>
                            <br *ngIf="node.buttons && node.buttons.length > 0">
                            <div [class]="'row align-items-end d-none ' + (node.textHtml != undefined ? 'd-xxl-flex' : '')">
                                <div class="d-inline-flex flex-wrap gap-2 position-relative" style="z-index: 1;">
                                    <div *ngFor="let button of node.buttons" [class]="button.divClass">
                                        <a *ngIf="button.routerLink != undefined" [routerLink]="button.routerLink" [class]="'mx-1 text-nowrap btn ' + button.class" target="_blank">
                                            <i *ngIf="button.iconClass != undefined" [class]="'fa fa-fw ' + button.iconClass" style="height: 20px;"></i>
                                            <img *ngIf="button.iconUrl != undefined" [src]="button.iconUrl" style="height: 24px; padding-bottom: 2px;">
                                            &nbsp;{{ button.text }}
                                        </a>
                                        <a *ngIf="button.url != undefined" [href]="button.url" [class]="'mx-1 text-nowrap btn ' + button.class" target="_blank">
                                            <i *ngIf="button.iconClass != undefined" [class]="'fa fa-fw ' + button.iconClass" style="height: 20px;"></i>
                                            <img *ngIf="button.iconUrl != undefined" [src]="button.iconUrl" style="height: 24px; padding-bottom: 2px;">
                                            &nbsp;{{ button.text }}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="node.image != undefined || (node.images != undefined && node.images.length > 0)" class="col-8 col-md-3 align-self-center">
                        <br class="d-block d-md-none">
                        <div *ngIf="node.images != undefined && node.images.length > 0" ngxViewer>
                            <img *ngFor="let image of node.images; let i = index;" [src]="image" [class]="'cursor-pointer col-md-2 card-img mx-auto d-' + (i == 0 ? 'block' : 'none')" style="max-width: 400px;">
                            <div class="card-footer text-center fw-light fst-italic">{{ getImageName(node.images[0]) }} <b>(1 of {{node.images.length}})</b></div>
                        </div>
                        <div *ngIf="node.image != undefined" ngxViewer>
                            <img src="{{node.image}}" class="cursor-pointer col-md-2 card-img mx-auto d-block" style="max-width: 400px">
                            <div class="card-footer text-center fw-light fst-italic">{{ getImageName(node.image) }}</div>
                        </div>
                    </div>
                </div>
                <br *ngIf="node.textHtml"><br *ngIf="node.textHtml">
            </div>
        </div>

        <ng-container *ngIf="node.children && node.children.length > 0">
            <ng-container *ngFor="let child of node.children; let i = index">
                <ng-container *ngTemplateOutlet="tree;context:{node:child,level:(level+1),nodeIndex:i,parentNodeIndex:nodeIndex,parentIndexLabel:getIndexLabel(nodeIndex, parentIndexLabel)}">
                </ng-container>
            </ng-container>
        </ng-container>

    </section>
</ng-template>