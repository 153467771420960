import { Component, Input } from '@angular/core';
import PageUtils from '../../../utils/pageutils'

declare var bootstrap: any;

@Component({
  selector: 'app-tutorial-content',
  templateUrl: './tutorial-content.component.html',
  styleUrls: ['./tutorial-content.component.sass']
})
export class TutorialContentComponent {
  @Input() node : any;
  @Input() level : any = 0;
  @Input() nodeIndex : any = 0;
  @Input() parentNodeIndex : any = 0;
  @Input() parentIndexLabel : any = '';

  ngAfterViewInit() {
    $('a').on("click",(e)=>{
      var hash = (<any>e.currentTarget).hash;
      if (hash) {
        e.preventDefault();
        window.location.hash = hash;
        document.querySelector(hash).scrollIntoView({ behavior: "smooth", block: "start" });
      }
    });
  }

  getIndexLabel(index, parentIndexLabel) {
    return (parentIndexLabel ? parentIndexLabel : '') + (index+1) + '.';
  }
  getTitleHtml(titleHtml, level, nodeIndex, parentIndexLabel) {
    let ident = '&emsp;'.repeat(level);
    return ident + this.getIndexLabel(nodeIndex, parentIndexLabel) + '&nbsp;' + titleHtml;
  }
  getImageName(imageFilename) {
    var filename = imageFilename.split('/').pop();
    var filenameWithoutExtension = filename.split('.').slice(0, -1).join('.');
    return filenameWithoutExtension;
  }
  isSmallDevice() {
    return PageUtils.isSmallDevice();
  }

  copyMessage(val: string){
    PageUtils.copyMessage(val, bootstrap);
  }

}
