import { Component, Input } from '@angular/core';

var bootstrap: any;

@Component({
  selector: 'app-tutorial-page',
  templateUrl: './tutorial-page.component.html',
  styleUrls: ['./tutorial-page.component.sass']
})
export class TutorialPageComponent {

  @Input() rows: any;

  ngAfterViewInit() {
    document.addEventListener('scroll', (event) => {
      var sections = document.querySelectorAll('#navbar-tutorial .nav-link');
      var scrollPosition = window.scrollY + window.innerHeight / 2; // Center of viewport
  
      sections.forEach(function (section) {
        var selector = "#" + section.getAttribute("href").split("#")[1];
        var target = document.querySelector(selector) as HTMLElement;
        if (target.offsetTop <= scrollPosition && target.offsetTop + target.offsetHeight > scrollPosition) {
          section.classList.add('active');
        } else {
          section.classList.remove('active');
        }
      });
    });
  }

}