import { Component } from '@angular/core';
import jsonDocTutorial from '../../../../locale/data/doc-tutorial.json';

@Component({
  selector: 'app-doc',
  templateUrl: './doc.component.html',
  styleUrls: ['./doc.component.sass']
})
export class DocComponent {
  rows: any = jsonDocTutorial;

}
