import { Component, Input } from '@angular/core';
import PageUtils from '../../../utils/pageutils'

declare var bootstrap: any;

@Component({
  selector: 'app-tutorial-list',
  templateUrl: './tutorial-list.component.html',
  styleUrls: ['./tutorial-list.component.sass']
})
export class TutorialListComponent {
  @Input() list : any;

  isSmallDevice() {
    return PageUtils.isSmallDevice();
  }

  copyMessage(val: string){
    PageUtils.copyMessage(val, bootstrap);
  }

}
